import type { UserVariables } from '../../@types/User';

interface DevCycleVariablesBetaResponse {
  'text-gen-beta': {
    _id: '641074f64bf7eb24e7dbaf1f';
    key: 'text-gen-beta';
    type: 'Boolean';
    value: boolean;
  };
}

interface DevCycleVariablesBeta1Response {
  'text-gen-beta-1': {
    _id: '64258e7d4813b50fd15785e3';
    key: 'text-gen-beta-1';
    type: 'Boolean';
    value: true;
  };
  'text-gen-beta1-limit': {
    _id: '642591134813b50fd1578753';
    key: 'text-gen-beta1-limit';
    type: 'Number';
    value: number;
  };
}

const MAX_LIMIT = 30;

export async function getVariables(userId: string): Promise<UserVariables> {
  if ((globalThis as any).OFFLINE_AUTH) {
    return {
      isInBeta: false,
      limit: MAX_LIMIT,
    };
  }

  const response = await fetch(
    'https://bucketing-api.devcycle.com/v1/variables',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: DEVCYCLE_KEY,
      },
      body: JSON.stringify({
        user_id: userId,
      }),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch variables: ${response.status}`);
  }

  const json = await response.json<any>();

  // handles group 1
  if (json['text-gen-beta']) {
    const response = json as DevCycleVariablesBetaResponse;

    if (response['text-gen-beta'].value) {
      return {
        isInBeta: true,
        limit: MAX_LIMIT,
      };
    }
  }

  // handles group 2
  if (json['text-gen-beta-1']) {
    const response = json as DevCycleVariablesBeta1Response;

    return {
      isInBeta: true,
      limit: response['text-gen-beta1-limit'].value,
    };
  }

  // handles not in a beta group
  return {
    isInBeta: false,
    limit: 0,
  };
}
